/**
 * Initializes menu offset adjustments based on Wisepops banner visibility.
 */
export default function initMenuOffset() {
  /**
   * Adjusts menu offset based on Wisepops banner visibility.
   */
  function adjustMenuOffset() {
    const wisepopsBanner = document.querySelector('[id^=wisepops-root-]');
    const bannerHeight =
      wisepopsBanner && window.getComputedStyle(wisepopsBanner).display !== 'none' ? wisepopsBanner.offsetHeight : 0;

    adjustMenuDropPosition(bannerHeight);
    adjustMenuHeaderShadowPosition(bannerHeight);
  }

  /**
   * Adjusts menu drop position based on Wisepops banner height.
   *
   * @param {number} bannerHeight the height of the Wisepops banner
   */
  function adjustMenuDropPosition(bannerHeight) {
    const menuDrop = document.querySelector('.menu-drop');
    if (!menuDrop) {
      return;
    }

    if (window.innerWidth > 1080) {
      menuDrop.style.paddingTop = '0px';

      return;
    }

    menuDrop.style.paddingTop = `${70 + bannerHeight}px`;
  }

  /**
   * Adjusts menu header shadow position based on Wisepops banner height.
   *
   * @param {number} bannerHeight the height of the Wisepops banner
   */
  function adjustMenuHeaderShadowPosition(bannerHeight) {
    const id = 'custom-header-shadow-position';
    const customStyle = document.getElementById(id);
    if (customStyle) {
      customStyle.remove();
    }

    if (window.innerWidth > 1080) {
      return;
    }

    const styleElement = document.createElement('style');
    styleElement.id = id;
    styleElement.innerHTML = `
      .menu-drop_inner::after {
        top: ${78 + bannerHeight}px;
      }
    `;

    document.head.appendChild(styleElement);
  }

  const observer = new MutationObserver((mutations) => mutations.forEach(() => adjustMenuOffset()));

  observer.observe(document.body, { attributes: true, childList: true, subtree: true });

  adjustMenuOffset();
}
